.fhir-ui__Header {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.fhir-ui__Title {
  margin-right: 10px;
  margin-bottom: 0;
}

.fhir-ui__Badge {
  margin-right: 10px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  background-color: #6c757d;
}

.fhir-ui__BadgeSecondary {
  margin-right: 10px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  background-color: #f8f9fa;
  color: #000;
}

.fhir-ui__Value {
  display: flex;
  margin-bottom: 5px;
  font-size: 90%;
}

.fhir-ui__Value :not(.fhir-ui__Value-label) {
  word-break: break-all;
}

.fhir-ui__Value-label {
  text-transform: uppercase;
  font-weight: bold;
  color: #6c757d;
  margin: 0 10px 0 0;
}

/* Based on bootstrap striped-table */
.fhir-ui__Table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.fhir-ui__TableHeader,
.fhir-ui__TableCell {
  padding: 0.4rem 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.fhir-ui__TableHeader {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.fhir-ui__TableHeader--expand {
  width: 100%;
}

.fhir-ui__Table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.fhir-ui__Table tbody .fhir-ui__TableRow:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.fhir-ui__ValueSection-label {
  margin-top: 5px;
  font-size: 1.1em;
  color: #6c757d;
  text-transform: uppercase;
  font-weight: 700;
}

.fhir-ui__ValueSection-body {
  padding: 0 10px;
}

.fhir-ui__MissingValue {
  font-weight: 700;
}

.fhir-ui__NotEnoughData {
  background-color: rgba(0, 0, 0, 0.01);
  padding: 5px 15px;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.fhir-datatype__Reference {
  display: inline-block;
}

.fhir-datatype__Reference__title {
  font-weight: 700;
  margin-right: 10px;
}

.fhir-datatype__CodeableConcept-item-label {
  font-size: 80%;
  font-weight: 400;
}

.fhir-resource__AllergyIntolerance__grouping {
  display: flex;
  align-items: center;
}

/* Img */
.fhir-datatype__Img__item {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.fhir-datatype__BinaryText__block {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 0.25rem;
  word-break: break-word;
  font-family: monospace;
  font-size: 16px;
}

.fhir-resource__CarePlan__activity {
  margin-bottom: 0.5rem;
}

.fhir-resource__CarePlan__activity-title {
  font-weight: bold;
}

.fhir-resource__Claim__item-level {
  height: 100%;
  height: 50px;
  margin: -12px;
  display: flex;
}

.fhir-resource__Claim__item-level-fill {
  width: 10px;
  height: 100%;
  background: #dee2e6;
}

.fhir-resource__ClaimResponse-item-adjudication,
.fhir-resource__ClaimResponse-added-item-adjudication {
  display: flex;
  margin-bottom: 5px;
}

.fhir-resource__ClaimResponse-item-adjudication-category,
.fhir-resource__ClaimResponse-added-item-adjudication-category {
  display: flex;
  margin-right: 10px;
}

.fhir-datatype__Telecom__item-label {
  margin-right: 10px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  background-color: #f8f9fa;
  color: #606060;
}

.fhir-resource__ObservationGraph {
  margin: 0.5rem 0;
  position: relative;
}

.fhir-resource__ObservationGraph__value-wrapper {
  position: relative;
}

.fhir-resource__ObservationGraph__value-actual {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.fhir-resource__ObservationGraph__value-unit {
  display: inline-block;
}

.fhir-resource__ObservationGraph__progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.fhir-resource__ObservationGraph__progress-value {
  opacity: 0.5;
  width: 12px;
  height: 12px;
  margin-top: 2px;
  position: absolute;
  background-color: #007bff;
  border-radius: 50%;
}

.fhir-resource__ObservationGraph__progress-bar--outside-range {
  color: #6c757d;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.fhir-resource__ObservationGraph__progress-bar--inside-range {
  background-color: #0003;
}

/* HumanName */
.fhir-datatype__HumanName__not-primary-block {
  font-weight: 700;
}

.fhir-datatype__HumanName__Header--primary {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
}

.fhir-datatype__HumanName__use-block {
  margin-left: 3px;
}

.fhir-resource__Patient__patientContact-relationship {
  font-weight: 700;
}

.fhir-resource__Patient__patient-avatar {
  border: 4px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.fhir-resource__Patient__patient-block {
  display: flex;
  align-items: baseline;
}

.fhir-resource__Patient__BirthDate-block {
  font-weight: 700;
  color: #6c757d;
}

.fhir-resource__Practitioner__patientContact-relationship {
  font-weight: 700;
}

.fhir-resource__Practitioner__practitioner-avatar {
  border: 4px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.fhir-resource__Questionnaire-list {
  list-style: none;
  border: 1px solid #f0f0f0;
  padding: 2px;
  margin: 2px;
}

.fhir-resource__Questionnaire-list-title {
  background-color: #f6f6f6;
  padding: 2px 2px 4px 2px;
}

.fhir-resource__Questionnaire-questions-list {
  list-style: none;
}

.fhir-resource__Questionnaire-questions-list li {
  border-bottom: 1px solid #f5f5f5;
}

.fhir-resource__Questionnaire-questions-list li:last-child {
  border-bottom: none;
}

.fhir-resource__Questionnaire-questions-list-item-details {
  font-size: 80%;
  color: #606060;
}

.fhir-resource__Questionnaire-questions-list-item-details-el {
  display: inline-block;
  margin-right: 15px;
}

.fhir-resource__QuestionnaireResponse-list {
  list-style: none;
  border: 1px solid #f0f0f0;
  padding: 2px;
  margin: 2px 2px 2px 20px;
}

.fhir-resource__QuestionnaireResponse-list-title {
  background-color: #f6f6f6;
  padding: 2px 2px 4px 2px;
}

.fhir-resource__QuestionnaireResponse-questions-list {
  list-style: none;
}

.fhir-resource__QuestionnaireResponse-questions-list li {
  border-bottom: 1px solid #f5f5f5;
}

.fhir-resource__QuestionnaireResponse-questions-list li:last-child {
  border-bottom: none;
}

.fhir-resource__QuestionnaireResponse-questions-list-item-details {
  margin-left: 20px;
}

.fhir-resource__QuestionnaireResponse-questions-list-item-details-el {
  display: inline-block;
  margin-right: 15px;
  color: blue;
}

.fhir-container__CodeBlock {
  padding: 1.5rem;
  color: #f8f9fa;
  background-color: #343a40;
  border-radius: 0.25rem;
  word-break: break-word;
  font-family: monospace;
  font-size: 16px;
  overflow-x: scroll;
}

.fhir-container__ResourceContainer__card {
  background-color: #f8f9fa;
  border: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.fhir-container__ResourceContainer__card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.fhir-container__ResourceContainer__json-button-wrapper {
  position: absolute;
  right: 2rem;
  top: 0.5rem;
}

.fhir-container__ResourceContainer__json--visible {
  display: block;
  padding-top: 10px;
}

.fhir-container__ResourceContainer__json--hidden {
  display: none;
}

.fhir-container__ResourceContainer__json-button {
  color: #6c757d;
  border-color: #6c757d;
  cursor: pointer;
  padding: 0.15rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1rem;
  border-radius: 0.2rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #6c6c6c;
  transition: all 0.15s ease-in-out;
}

.fhir-container__ResourceContainer__json-button:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/**
 * This file contains global fhir-react library styles.
 * The styles for individual components are located in CSS files
 * placed next to the components themselves.
 **/

